import React from "react";




const ApsisFormEmbed = ({url}) => {


 return (


 <iframe title="apsis form" src={url} width="100%" height="680" 	style={{border:"0"}}></iframe>
       

  
 )
}

export default ApsisFormEmbed
